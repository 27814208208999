import './index.css';

function Home() {
  return (
    <div className="Home">

    </div>
  );
}

export default Home;
