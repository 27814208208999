import './index.css';
import React, {useState, useEffect} from 'react';
import Navigation from '../../components/Navigation';
import ScrollToTop from '../../components/ScrollToTop';
import Head from '../../components/Head';
import FadeBackground from '../../components/FadeBackground';

function Projects() {
  const [projects, setProjects] = useState([]);
  let loaded = false;

  useEffect(() => {
    fetch('https://api.github.com/users/CrowBarJonns/repos').then(response => {
      if (response.ok) return response.json();
      }
    ).then(json => {
      setProjects(json);
    });
  }, [])

  useEffect(() => {
      console.log(projects);
  }, [projects.length])

  return (
    <div className="Home">
    <ScrollToTop />
      <FadeBackground/>
      
      <div className="Padding">
        <Head title="Projects"/>

        <div className="Projects">
          
        </div>
      </div>
      <Navigation page="Projects" />
    </div>
  );
}

export default Projects;
