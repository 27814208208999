import './index.css';
import React from 'react';
import Navigation from '../../components/Navigation';
import ScrollToTop from '../../components/ScrollToTop';
import Head from '../../components/Head';
import FadeBackground from '../../components/FadeBackground';

function Socials() {

  return (
    <div className="Home">
    <ScrollToTop />
      <FadeBackground/>
      
      <div className="Padding">
        <Head title="Socials"/>

        <div className="Socials">
          
        </div>
      </div>
      <Navigation page="Socials" />
    </div>
  );
}

export default Socials;
