import React, {useState, useEffect} from 'react';


export default function FadeBackground(){

    const [offset, setOffset] = useState(0);

    const [height, setHeight] = useState(window.innerHeight);
  
    function handleWindowSizeChange() {
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
  
    useEffect(() => {
      const onScroll = () => setOffset(window.pageYOffset);
      // clean up code
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }, []);
  
    let ratio = ((height - offset) / height) - 0.4;

    return(
      <div className="Introduction" style={{opacity: ratio}} />
    )
}