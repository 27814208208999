import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';

export default function ExpandableNav(props){
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    let pages = ["Home", "Experience", "Projects", "Skills", "Socials", "Updates"];
    pages = pages.reduce((pag, curr) => {
        if(curr === props.title){
            return [curr, ...pag]
        }
        return [...pag, curr];
    }, [])
    let element;
    let elements = [];

    const nav = (page) => {
        switch(page){
            case "Experience": navigate('/experience'); break;
            case "Projects": navigate('/projects'); break;
            case "Skills": navigate('/skills'); break;
            case "Socials": navigate('/socials'); break;
            case "Updates": navigate('/blog'); break;
            default: navigate('/'); break;
        }
    }

    if(!open){
        element =
        <div id="navmenu">
            <h1  id="title" onClick={() => {(open ? setOpen(false) : setOpen(true))}}>{props.title}</h1>
        </div>
    } else {
        for(let page in pages) {
            let newPage = <h1 style={{animationDelay: (page-1)*0.125 + 's'}} id="title" className={(pages[page] !== props.title ? "expandedTitle" : "noAnimation")} onClick={() => {nav(pages[page])}}>{pages[page]}</h1>;
            elements.push(newPage);
        }
        element =   <div id="navmenu" onClick={() => {(open ? setOpen(false) : setOpen(true))}}>
                        {elements}
                    </div>
    }

    return(
            <div id="navcontainer">
                {element}
                <div id="navdropdown">
                    <h3 onClick={() => {(open ? setOpen(false) : setOpen(true))}}>{(open ? '<' : '>')}</h3>
                </div>
            </div>
    )
}