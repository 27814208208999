import './index.css';
import React from 'react';
import Navigation from '../../components/Navigation';
import ScrollToTop from '../../components/ScrollToTop';
import Head from '../../components/Head';
import FadeBackground from '../../components/FadeBackground';

function Updates() {

  return (
    <div className="Home">
    <ScrollToTop />
      <FadeBackground/>
      
      <div className="Padding">
        <Head title="Updates"/>

        <div className="Blog">
          
        </div>

      </div>
      <Navigation page="Updates" />
    </div>
  );
}

export default Updates;
