import './index.css';
import React from 'react';
import Navigation from '../../components/Navigation';
import ScrollToTop from '../../components/ScrollToTop';
import Head from '../../components/Head';
import FadeBackground from '../../components/FadeBackground';

function Skillset() {
  const hue = 300;
  const saturation = 40;

  const skillVal = (skill) => {
    let value = skill.level;
    if (value >= 75) {
      return "Expert";
    } else if (value >= 50) {
      return "Proficient";
    } else if (value >= 25) {
      return "Intermediate";
    } else if (value >= 0) {
      return "Beginner";
    }
  }

  const skillsData= [
    {
      type: 'Azure',
      level: 24,
      category: 'tech',
    },  
    {
      type: 'React.js',
      level: 67,
      category: 'tech',
    }, 
    {
      type: 'Node.js',
      level: 52,
      category: 'tech',
    }, 
    {
      type: '.NET Core',
      level: 67,
      category: 'tech',
    }, 
    {
      type: 'Git',
      level: 80,
      category: 'tech',
    }, 
    {
      type: 'PHP',
      level: 44,
      category: 'tech',
    }, 
    {
      type: 'Angular.js',
      level: 48,
      category: 'tech',
    },  
    {
      type: 'Typescript',
      level: 56,
      category: 'tech',
    },  
    {
      type: 'Javascript',
      level: 72,
      category: 'tech',
    },  
    {
      type: 'MongoDB',
      level: 52,
      category: 'tech',
    },  
    {
      type: 'SQL',
      level: 80,
      category: 'tech',
    },  
    {
      type: 'Java',
      level: 42,
      category: 'tech',
    },  
    {
      type: 'Office 365',
      level: 75,
      category: 'tech',
    },
    {
      type: 'Needs Analysis',
      level: 85,
      category: 'hard',
    },  
    {
      type: 'Application Support',
      level: 100,
      category: 'hard',
    },  
    {
      type: 'UX/UI Design',
      level: 48,
      category: 'hard',
    },  
    {
      type: 'Software Testing',
      level: 61,
      category: 'hard',
    },  
    {
      type: 'Bug Triage',
      level: 42,
      category: 'hard',
    }, 
    {
      type: 'Software Documentation',
      level: 73,
      category: 'hard',
    }, 
    {
      type: 'Project Management',
      level: 62,
      category: 'hard',
    }, 
    {
      type: 'Conflict Resolution',
      level: 80,
      category: 'soft',
    }, 
    {
      type: 'Adaptibility',
      level: 95,
      category: 'soft',
    },  
    {
      type: 'Google-Fu',
      level: 100,
      category: 'soft',
    }, 
    {
      type: 'Technical Acumen',
      level: 73,
      category: 'soft',
    }, 
    {
      type: 'Relationship Building',
      level: 81,
      category: 'soft',
    }, 
    {
      type: 'People Management',
      level: 63,
      category: 'soft',
    }, 
    {
      type: 'Public Speaking',
      level: 74,
      category: 'soft',
    }, 
  ]

  const skillMap = {"tech": [], "hard": [], "soft": []};

  skillsData.map((skills, i) => {
    skillMap[skills.category].push(skills);
    return null;
  });

  for (const [k, v] of Object.entries(skillMap)) {
    skillMap[k] = v.sort((a, b) => b.level - a.level);
  }

  const skillList = {"tech": [], "hard": [], "soft": []};

  let tech = [];

  for (const [k, v] of Object.entries(skillMap)) {
    skillList[k] = v.map((skill, i) => {
      return (
        <li
          key={skill.type}
          style={{ width: skill.level + "%", backgroundColor: "hsl(" + hue + ", " + saturation + "%, 100 / (" + i + 3.5 + ") %)" }}
        >
          <p>{skill.type}<span>{skillVal(skill)}</span></p>
        </li>)
    })
  }

  console.log(skillList);


  return (
    <div className="Home">
    <ScrollToTop />
      <FadeBackground/>
      
      <div className="Padding">
        <Head title="Skills"/>

        <div className="Skills">
          <div className="Technical">
            <h3>Technical Skills</h3>
            {skillList.tech}
          </div>
          <div className="Hard">
            <h3>Hard Skills</h3>
            {skillList.hard}
          </div>
          <div className="Soft">
            <h3>Soft Skills</h3>
            {skillList.soft}
          </div>
        </div>
      </div>
      <Navigation page="Skills" />
    </div>
  );
}

export default Skillset;
