import './index.css';
import headshot from './headshot.png';
import React from 'react';
import Typewriter from 'typewriter-effect';
import Navigation from '../../components/Navigation';
import ScrollToTop from '../../components/ScrollToTop';
import Head from '../../components/Head';
import FadeBackground from '../../components/FadeBackground';

function Home() {

  return (
    <div className="Home">
      <ScrollToTop />
      <FadeBackground/>
      
      <div className="Padding">
        <Head title="Home"/>

        <Typewriter
          options={{
            strings: ['Kia ora!', 'Hello!', "'Sup?", "G'day!", "Welcome!"],
            autoStart: true,
            loop: true,
            delay: 100,
            deleteSpeed: 100,
          }}
        />
        <div className="Bio">
          <span id="me">
            <h1>My name is Macauley Cunningham,<br/>and this is my portfolio.</h1>
            <br></br>
            <h2>I am a:</h2>
            <p id="p1" title="As you will see below!">- Fullstack Developer</p>
            <p id="p2" title="Bachelor of Computer and Information Science from AUT">- University Graduate</p>
            <p id="p3" title="Mostly Dungeons and Dragons 5E, some Call of Cthulu and Pathfinder 3E">- Dungeon Master</p>
            <p id="p4" title="Freelance and Hobby Work">- Fantasy Writer</p>
            <p id="p5" title="Click this one, it is kind of wild">- Former <a href="https://www.newshub.co.nz/home/entertainment/2020/04/coronavirus-concern-teens-spending-too-much-lockdown-time-gaming.html">"Gaming Teen"</a></p>
          </span>
          <img className="pixel-border" src={headshot} alt="Macauley Cunningham" />
        </div>
      </div>
      <Navigation page="Home" />
    </div>
  );
}

export default Home;
