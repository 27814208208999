import React from 'react';
import ExpandableNav from "./ExpandableNav";

export default function Head(props){
  return(
      <span id="head">
        <span>
          <h1>Macauley Cunningham</h1>
          <h2>Software Development Graduate</h2>
          <h2>Software Services Consultant @ Vista</h2>
          <h2>-------</h2>
          <h2 >Vice President Academic @ AUTSA</h2>
          <h2 >Library Shelver @ Auckland Council</h2>
          <h2 >Customer Service Rep @ Briscoe Group</h2>
        </span>
        <ExpandableNav title={props.title}/>
      </span>
  )
}