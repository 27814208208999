import './index.css';
import {React} from 'react';
import Navigation from '../../components/Navigation';
import ScrollToTop from '../../components/ScrollToTop';
import Head from '../../components/Head';
import FadeBackground from '../../components/FadeBackground';
import exp from '../../exp.json';

function Experience() {
  let roles = exp.roles;
  const expContainer = roles.map((role) => {
      let roleRender = (<div className="Role" key={role.title}>
        <h2>{(role.type !== 'Academic' ? "Title: " : "")}{role.title} &middot; {role.dateStart} - {role.dateEnd}</h2>
        <h3>{role.company} &middot; {role.location}</h3>
        <h2>{(role.type !== 'Academic' ? "Responsibilities" : "Details")}</h2>
        <ul>{role.responsibilities.map(responsibility => <li key={responsibility}>{responsibility}</li>)}</ul>
        <h2>Skills Involved</h2>
        <ul className="Skill">/ {role.skills.map(skill => <li key={skill}>{skill} /</li>)}</ul>
        <hr/>
      </div>)
      return (roleRender)
    });

 
  return (
    <div className="Home">
    <ScrollToTop />
      <FadeBackground/>
      
      <div className="Padding">
        <Head title="Experience"/>

        <div className="Experience">
          <h1>Experience</h1>
          {expContainer}
        </div>
      </div>
      <Navigation page="Experience" />
    </div>
  );
}

export default Experience;
