import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import expe from "./icons/experience.svg";
import home from "./icons/home.svg";
import proj from "./icons/projects.svg";
import skil from "./icons/skills.svg";
import soci from "./icons/socials.svg";
import upda from "./icons/updates.svg";

function Navigation(props) {
    let navigate = useNavigate(); 

    const [offset, setOffset] = useState(0);
  
    const [height, setHeight] = useState(window.innerHeight);
  
    function handleWindowSizeChange() {
        setHeight(window.innerHeight);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
  
    useEffect(() => {
      const onScroll = () => setOffset(window.pageYOffset);
      // clean up code
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
    }, []);
  
    let ratio = ((height - offset) / height) - 0.4;

    const goToTop = () => {
        let top = (ratio <= 0.01 ? 0 : height);

        window.scrollTo({
            top: top,
            behavior: 'smooth',
        });
        console.log("Going down");
    };

    return (
        <div className="Navigation">
          <h1>Find Out More About Macauley:</h1>
          <h1 onClick={() => goToTop()} id="scroll">{(ratio <= 0.01 ? '<' : '>')}</h1>
          
          <div className="Icons">
            {props.page !== "Home" ? 
            <span id="hom" className="Icon" onClick={() => navigate("/")}>
                <img alt="" src={home}></img>
                <h1>Home</h1>
            </span> : null}
            {props.page !== "Experience" ? 
            <span id="exp" className="Icon" onClick={() => navigate("/experience")}>
                <img alt="" src={expe}></img>
                <h1>Experience</h1>
            </span> : null}
            {props.page !== "Projects" ? 
            <span id="pro" className="Icon" onClick={() => navigate("/projects")}>
                <img alt="" src={proj}></img>
                <h1>Projects</h1>
            </span> : null}
            {props.page !== "Skills" ? 
            <span id="ski" className="Icon" onClick={() => navigate("/skills")}>
                <img alt="" src={skil}></img>
                <h1>Skills</h1>
            </span> : null}
            {props.page !== "Socials" ? 
            <span id="soc" className="Icon" onClick={() => navigate("/socials")}>
                <img alt="" src={soci}></img>
                <h1>Socials</h1>
            </span> : null}
            {props.page !== "Updates" ? 
            <span id="upd" className="Icon" onClick={() => navigate("/blog")}>
                <img alt="" src={upda}></img>
                <h1 >Updates</h1>
            </span> : null}
          </div>
      </div>
    )
}

export default Navigation;