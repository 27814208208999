import React, {useState, useEffect} from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import ScrollToTop from '../components/ScrollToTop';
import D_HM from './desktop/Home';
import D_XP from './desktop/Experience';
import D_PJ from './desktop/Projects';
import D_SK from './desktop/Skillset';
import D_SC from './desktop/Socials';
import D_UP from './desktop/Updates';
import D_FT from './desktop/Family';
import D_AC from './desktop/Academics';
import M_HM from './mobile/Home';

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= height;
  
  return (
    <div id="App">
      <BrowserRouter>
      <ScrollToTop />
        {(!isMobile ? 
        <Routes>
            <Route exact path="/" element={<D_HM />}/>
            <Route exact index element={<D_HM />} />
            <Route exact path="projects" element={<D_PJ />} />
            <Route exact path="skills" element={<D_SK />} />
            <Route exact path="socials" element={<D_SC />} />
            <Route exact path="experience" element={<D_XP />} />
            <Route exact path="blog" element={<D_UP />} />
            <Route exact path="family" element={<D_FT />} />
            <Route exact path="academics" element={<D_AC />} />
            <Route path="*" element={<D_HM />} />
          </Routes>
        :
        <Routes>
          <Route exact path="/" element={<M_HM />}/>
            <Route index element={<M_HM />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
  
}

export default App;