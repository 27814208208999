import './index.css';
import React, { useState } from 'react';
import Navigation from '../../components/Navigation';
import ScrollToTop from '../../components/ScrollToTop';
import Head from '../../components/Head';
import FadeBackground from '../../components/FadeBackground';
import Tree from 'react-d3-tree';

function FamilyTree() {

    const [familia, setFamilia] = useState({});

  return (
    <div className="Home">
    <ScrollToTop />
      <FadeBackground/>
      
      <div className="Padding">
        <Head title="Family Tree"/>

        <div className="FamilyTree">
            <div id="treeWrapper" style={{ width: '50em', height: '20em' }}>
                <Tree 
                    data={familia}
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf" 
                    orientation="vertical"
                />
            </div>
        </div>
      </div>
      <Navigation page="Family Tree" />
    </div>
  );
}

export default FamilyTree;
