import React, { useState } from 'react';

function Academics() {

    const retAcademics = () => {
        fetch('https://profiles.auckland.ac.nz/api/users', {
            method: 'POST',
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json',
                'Host': 'https://profiles.auckland.ac.nz',
                'Postman-Token': 'd46efcbb-62f4-4cd0-a3ca-2fe2a7937dcf'
            },
            body: {
                "params": {
                    "by": "text",
                    "type": "user",
                    "text": ""
                },
                "pagination": {
                    "perPage": 1000,
                    "totalIsLowerBound": true,
                    "startFrom": 0
                }
            }
        })
        .then(response => response.json())
        .then(response => console.log(JSON.stringify(response)))
    }


    return(
        <div>
            <button onClick={retAcademics}>Click Me For Academics!</button>
            
        </div>
    )

}

export default Academics;